// Angular
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTooltipModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import {InlineSVGModule} from 'ng-inline-svg';

// Layout partials
import {
	LanguageSelectorComponent,
	NotificationComponent,
	QuickActionComponent,
	QuickPanelComponent,
	ScrollTopComponent,
	SearchDefaultComponent,
	SearchDropdownComponent,
	SearchResultComponent,
	StickyToolbarComponent,
	Subheader1Component,
	Subheader2Component,
	Subheader3Component,
	Subheader4Component,
	Subheader5Component,
	CartComponent,
	ContentAnimateDirective,
	HeaderDirective,
	MenuDirective,
	OffcanvasDirective,
	ScrollTopDirective,
	SparklineChartDirective,
	StickyDirective,
	TabClickEventDirective,
	ToggleDirective,
	UppercaseDirective,
} from './';

import { PortletModule } from './portlet/portlet.module';

@NgModule({
	declarations: [
		ScrollTopComponent,
		QuickPanelComponent,
		SearchResultComponent,
		StickyToolbarComponent,
		Subheader1Component,
		Subheader2Component,
		Subheader3Component,
		Subheader4Component,
		Subheader5Component,
		LanguageSelectorComponent,
		NotificationComponent,
		QuickActionComponent,
		SearchDefaultComponent,
		SearchDropdownComponent,
		CartComponent,
		ContentAnimateDirective,
		HeaderDirective,
		MenuDirective,
		OffcanvasDirective,
		ScrollTopDirective,
		SparklineChartDirective,
		StickyDirective,
		TabClickEventDirective,
		ToggleDirective,
		UppercaseDirective,
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		InlineSVGModule,
		PortletModule,
		NgbDropdownModule,
		NgbTooltipModule,
		NgbNavModule,
	],
	exports: [
		PortletModule,
		// topbar components
		QuickPanelComponent,
		ScrollTopComponent,
		SearchResultComponent,
		StickyToolbarComponent,
		Subheader1Component,
		Subheader2Component,
		Subheader3Component,
		Subheader4Component,
		Subheader5Component,
		LanguageSelectorComponent,
		NotificationComponent,
		QuickActionComponent,
		SearchDefaultComponent,
		SearchDropdownComponent,
		CartComponent,
		ContentAnimateDirective,
		HeaderDirective,
		MenuDirective,
		OffcanvasDirective,
		ScrollTopDirective,
		SparklineChartDirective,
		StickyDirective,
		TabClickEventDirective,
		ToggleDirective,
		UppercaseDirective,
	],
})
export class PartialsModule {
}
