// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/error-page/error-page.component';
// Services
import { MenuResolver, AuthorizationGuard } from 'informa-ui-core-lib';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./views/pages/auth/auth.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: '',
    canActivate: [AuthorizationGuard],
    component: BaseComponent,
    resolve: {
      menu: MenuResolver,
    },
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./views/pages/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'globalSettings',
        loadChildren: () =>
          import('./views/pages/global-settings/global-settings.module').then(
            (m) => m.GlobalSettingsModule
          ),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./views/pages/main/main.module').then((m) => m.MainModule),
      },
      {
        path: 'forbidden',
        component: ErrorPageComponent,
        data: {
          type: 'error-v6',
          code: 403,
          title: '403... Access forbidden',
          desc:
            "Looks like you don't have permission to access for requested page.<br> Please, contact administrator",
        },
      },
      { path: 'error/:type', component: ErrorPageComponent },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: '**', redirectTo: 'home', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
