// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Portlet
import { PortletComponent } from './portlet.component';
import { PortletHeaderComponent } from './portlet-header.component';
import { PortletBodyComponent } from './portlet-body.component';
import { PortletFooterComponent } from './portlet-footer.component';

import { KtDialogService } from './kt-dialog.service';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		PortletComponent,
		PortletHeaderComponent,
		PortletBodyComponent,
		PortletFooterComponent,
	],
	exports: [
		PortletComponent,
		PortletHeaderComponent,
		PortletBodyComponent,
		PortletFooterComponent,
	],
	providers: [
		KtDialogService
	]
})
export class PortletModule {
}
