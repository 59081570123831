// Angular
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { Observable, Subscription } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit, OnDestroy {
	// Public properties
	user: any;
	isLoggingOut: boolean;

	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	@Input() avatar = true;
	@Input() greeting = true;
	@Input() badge: boolean;
	@Input() icon: boolean;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(
		private router: Router,
		public oidcSecurityService: OidcSecurityService
	) {

	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		const authSubr = this.oidcSecurityService.userData$.subscribe(({ userData }) => {
      this.user = userData;
		});
		this.unsubscribe.push(authSubr);
	}

	/**
	 * Go to Log In Page
	 */
	logout() {
		this.isLoggingOut = true;
		this.oidcSecurityService.logoff();
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}
}
