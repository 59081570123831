import { Injectable } from '@angular/core';
import { NgModule } from '@angular/core';
import {
  AuthModule,
  LogLevel,
  StsConfigLoader,
  StsConfigHttpLoader,
  AbstractSecurityStorage,
} from 'angular-auth-oidc-client';

@Injectable()
export class CustomStorage implements AbstractSecurityStorage {
  read(key: string) {
    return localStorage.getItem(key);
  }

  write(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}

export const httpLoaderFactory = () => {
  const config$ = fetch('./assets/config.json')
    .then((response) => response.json())
    .then((config: any) => {
      return {
        authority: `${config.authUrl}/.well-known/openid-configuration`,
        redirectUrl: window.location.origin,
        clientId: 'adu',
        responseType: 'code',
        scope: 'openid email profile adu',
        postLogoutRedirectUri: window.location.origin,
        startCheckSession: true,
        silentRenew: true,
        silentRenewUrl: `${window.location.origin}/silent-renew.html`,
        postLoginRoute: '/home',
        forbiddenRoute: '/forbidden',
        unauthorizedRoute: '/auth/login',
        logLevel: LogLevel.Error,
        historyCleanupOff: true,
        storage: new CustomStorage(),
      };
    });

  return new StsConfigHttpLoader(config$);
};

@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: httpLoaderFactory,
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
