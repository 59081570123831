// Angular
import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

// Object-Path
import * as objectPath from 'object-path';
// Layout
import { LayoutConfigService, MenuConfigService, PageConfigService } from 'informa-ui-core-lib';
import { HtmlClassService } from '../html-class.service';
import { LayoutConfig , PageConfig } from '../config';
import { ActivatedRoute } from '@angular/router';


@Component({
	selector: 'kt-base',
	templateUrl: './base.component.html',
	styleUrls: ['./base.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit, OnDestroy {
	// Public variables
	selfLayout: string;
	asideDisplay: boolean;
	asideSecondary: boolean;
	subheaderDisplay: boolean;
	desktopHeaderDisplay: boolean;
	fitTop: boolean;
	fluid: boolean;

	// Private properties
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/


	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 * @param menuConfigService: MenuConfifService
	 * @param pageConfigService: PageConfigService
	 * @param htmlClassService: HtmlClassService
	 * @param store
	 */
	constructor(
		private actr: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		private layoutConfigService: LayoutConfigService,
		private menuConfigService: MenuConfigService,
		private pageConfigService: PageConfigService,
		private htmlClassService: HtmlClassService) {
			this.actr.data
			.pipe(map(data => data.menu))
			.subscribe(data => {
				const menuConfig = this.menuConfigService.buildMenuConfig(data);

				// register configs by demos
				const layoutConfig = new LayoutConfig().configs;
				layoutConfig.brand.self.skin = 'light';
				this.layoutConfigService.setConfig(layoutConfig, true);
				this.menuConfigService.loadConfigs(menuConfig);
				this.pageConfigService.loadConfigs(new PageConfig().configs);

				// setup element classes
				this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

				const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(updatedLayoutConfig => {
					// reset body class based on global and page level layout config, refer to html-class.service.ts
					document.body.className = '';
					this.htmlClassService.setConfig(updatedLayoutConfig);
				});
				this.unsubscribe.push(subscr);
			});

	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		sessionStorage.removeItem('switchUser');
		const config = this.layoutConfigService.getConfig();
		this.selfLayout = objectPath.get(config, 'self.layout');
		this.asideDisplay = objectPath.get(config, 'aside.self.display');
		this.subheaderDisplay = objectPath.get(config, 'subheader.display');
		this.desktopHeaderDisplay = objectPath.get(config, 'header.self.fixed.desktop');
		this.fitTop = objectPath.get(config, 'content.fit-top');
		this.fluid = objectPath.get(config, 'content.width') === 'fluid';

		// let the layout type change
		const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(cfg => {
			setTimeout(() => {
				this.selfLayout = objectPath.get(cfg, 'self.layout');
			});
		});
		this.unsubscribe.push(subscr);
		this.cdr.detectChanges();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}

}

