// Angular
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";

// NgBootstrap
import {
  NgbDropdownModule,
  NgbProgressbarModule,
  NgbTooltipModule,
  NgbNavModule,
} from "@ng-bootstrap/ng-bootstrap";
// Loading bar
import { LoadingBarModule } from "@ngx-loading-bar/core";
// Perfect Scrollbar
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
// SVG inline
import { InlineSVGModule } from "ng-inline-svg";
// Core Module
import { InformaUiCoreLibModule } from "informa-ui-core-lib";
import { PartialsModule } from "../partials/partials.module";

import { HeaderComponent } from "./header/header.component";
import { AsideLeftComponent } from "./aside/aside-left.component";
import { FooterComponent } from "./footer/footer.component";
import { SubheaderComponent } from "./subheader/subheader.component";
import { BrandComponent } from "./brand/brand.component";
import { TopbarComponent } from "./header/topbar/topbar.component";
import { MenuHorizontalComponent } from "./header/menu-horizontal/menu-horizontal.component";
import { BaseComponent } from "./base/base.component";
import { HtmlClassService } from "./html-class.service";
import { HeaderMobileComponent } from "./header/header-mobile/header-mobile.component";
import { ErrorPageComponent } from "./error-page/error-page.component";
import { UserProfileComponent } from "./header/user-profile/user-profile.component";

@NgModule({
  declarations: [
    BaseComponent,
    FooterComponent,

    // headers
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,

    // subheader
    SubheaderComponent,

    // topbar components
    TopbarComponent,

    // aside left menu components
    AsideLeftComponent,

    // horizontal menu components
    MenuHorizontalComponent,

    ErrorPageComponent,
    UserProfileComponent,
  ],
  exports: [
    BaseComponent,
    FooterComponent,

    // headers
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,

    // subheader
    SubheaderComponent,

    // topbar components
    TopbarComponent,

    // aside left menu components
    AsideLeftComponent,

    // horizontal menu components
    MenuHorizontalComponent,

    ErrorPageComponent,
    UserProfileComponent,
  ],
  providers: [HtmlClassService],
  imports: [
    CommonModule,
    RouterModule,
    InformaUiCoreLibModule,
    PartialsModule,
    PerfectScrollbarModule,
    FormsModule,
    LoadingBarModule,
    InlineSVGModule,
    // ng-bootstrap modules
    NgbProgressbarModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgbNavModule,
  ],
})
export class ThemeModule {}
