import { Subscription } from 'rxjs';
// Angular
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

// Layout
import {
  EnvService,
  ApplicationInsightsService,
  LoaderComponent,
  IdleTimeoutModalComponent,
} from 'informa-ui-core-lib';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  // Public properties
  loader: boolean;
  loaderComponent = LoaderComponent;
  // For idle timeout
  isAuthenticated: boolean;
  idleState = 'Not started.';
  timedOut = false;
  idleTimeoutModal: NgbModalRef;

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * @param router: Router
   * @param layoutConfigService: LayoutCongifService
   * @param splashScreenService: SplashScreenService
   */
  constructor(
    private router: Router,
    public oidcSecurityService: OidcSecurityService,
    private modalService: NgbModal,
    private idle: Idle,
    private env: EnvService,
    private applicationInsightsService: ApplicationInsightsService
  ) {
    if (this.env.config.isTimeoutSet) {
      // For Idle Timeout
      // sets an idle timeout of 55 mins.
      idle.setIdle(this.env.config.idlePeriod);
      // sets a timeout period of 5 mins. after 1 hr of inactivity, the user will be considered timed out.
      idle.setTimeout(this.env.config.timeoutPeriod);
      // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer idle.';
      });

      idle.onTimeout.subscribe(() => {
        this.idleTimeoutModal.close();
        this.idleState = 'Timed out!';
        this.timedOut = true;
        // log user out from ID server
        this.logout();
      });

      idle.onIdleStart.subscribe(() => {
        this.idleState = "You've gone idle!";
        idle.clearInterrupts();
        this.idleTimeoutModal = this.modalService.open(
          IdleTimeoutModalComponent,
          { size: 'lg', backdrop: 'static' }
        );
        this.idleTimeoutModal.result.then(
          (result) => {
            if (result) {
              idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
              idle.watch();
            }
          },
          (reason) => {}
        );
      });

      idle.onTimeoutWarning.subscribe((countdown) => {
        const minutes: number = Math.floor(countdown / 60);
        if (minutes > 0) {
          this.idleState = `You will time out in ${minutes} minutes ${
            countdown - minutes * 60
          } seconds!`;
        } else {
          this.idleState = 'You will time out in ' + countdown + ' seconds!';
        }
        if (this.idleTimeoutModal && this.idleTimeoutModal.componentInstance) {
          this.idleTimeoutModal.componentInstance.idleState = this.idleState;
        }
      });

      const authSubscription = this.oidcSecurityService.isAuthenticated$.subscribe(
        ({ isAuthenticated }) => {
          if (isAuthenticated) {
            idle.watch();
            this.timedOut = false;
            this.idleState = 'Started.';
          } else {
            idle.stop();
          }
        }
      );

      this.unsubscribe.push(authSubscription);
    }
  }

  logout() {
    this.oidcSecurityService.logoff();
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.applicationInsightsService.init();

    this.oidcSecurityService
      .checkAuth()
      .subscribe(
        ({ isAuthenticated, userData, accessToken, idToken, configId }) => {
          console.log('app authenticated: ', isAuthenticated);
        }
      );

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        // this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('kt-page--loaded');
        }, 1000);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
